import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew'
import Button from '@mui/material/Button'
import Container from '@mui/material/Container'
import Divider from '@mui/material/Divider'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { Button as EasyshipButton } from 'easyship-components'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import AppBar from '@/components/AppBar'
import Loading from '@/components/Loading'
import Spacer from '@/components/Spacer'

import FlagCard from './FlagCard'
import PreInspectionPackage, { PreInspectionPackageScreeningFlags } from './models/PreInspection'
import PreInspectionActions from './PreInspectionActions'
import PreInspectionPackageDetails from './PreInspectionPackageDetails'
import useGetNextPreInspectionQuery from './useGetNextPreInspectionQuery'
import useGetPreviousPreInspectionQuery from './useGetPreviousPreInspectionQuery'
import { useUpdatePreInspectionPackagesMutation } from './useUpdatePreInspectionPackagesMutation'

export default function PreInspectionPage() {
  const navigate = useNavigate()
  const {
    data: nextPreInspectionPackage,
    refetch,
    isFetching,
    remove: removeNext,
  } = useGetNextPreInspectionQuery()
  const {
    refetch: refetchPrevious,
    isFetching: isFetchingPrevious,
    remove: removePrevious,
  } = useGetPreviousPreInspectionQuery()
  const updatePreInspectionMutation = useUpdatePreInspectionPackagesMutation()
  const [currentFlag, setCurrentFlag] = useState<PreInspectionPackageScreeningFlags>()
  const [preInspectionPack, setPreInspectionPack] = useState<PreInspectionPackage>()
  const [flagIndex, setFlagIndex] = useState(1)
  const [previousClicked, setPreviousClicked] = useState(false)

  const hasPreInspectionPackage =
    preInspectionPack &&
    preInspectionPack.screeningFlags &&
    preInspectionPack.screeningFlags.length > 0

  const handleGetNext = () => {
    if (currentFlag && preInspectionPack) {
      const indexOfCurrentFlag = preInspectionPack.screeningFlags.indexOf(currentFlag)
      if (indexOfCurrentFlag < preInspectionPack.screeningFlags?.length - 1) {
        setCurrentFlag(preInspectionPack.screeningFlags[indexOfCurrentFlag + 1])
        setFlagIndex(flagIndex + 1)
      } else {
        setPreviousClicked(false)
        removeNext()
        refetch()
      }
    }
  }

  function handleGetPrevious() {
    removePrevious()
    refetchPrevious().then(({ data }) => {
      setFlagIndex(1)
      setPreInspectionPack(data)
      setPreviousClicked(true)
      setCurrentFlag(data?.screeningFlags[0])
    })
  }

  function handleActionClick(outcome: string) {
    updatePreInspectionMutation.mutate(
      {
        screeningFlagId: currentFlag?.id as number,
        outcome: outcome,
      },
      { onSuccess: handleGetNext },
    )
  }

  useEffect(() => {
    if (nextPreInspectionPackage) {
      setFlagIndex(1)
      setPreInspectionPack(nextPreInspectionPackage)
      setCurrentFlag(nextPreInspectionPackage?.screeningFlags[0])
    } else {
      setPreInspectionPack(undefined)
    }
  }, [nextPreInspectionPackage])

  return (
    <Stack height="100%">
      <AppBar title="Pre-Inspection">
        <Spacer />
        <Button variant="text" onClick={() => navigate('/')}>
          home
        </Button>
      </AppBar>
      {isFetching || isFetchingPrevious ? (
        <Loading text="Fetching Pre-inspection packages" />
      ) : (
        <Container maxWidth="sm" sx={{ my: 2 }}>
          {!hasPreInspectionPackage ? (
            <Typography display="flex" justifyContent="center" variant="caption">
              No Packages waiting to be Pre-Inspected
            </Typography>
          ) : (
            <Stack spacing={2} divider={<Divider light />}>
              <div>
                <Typography variant="h1" component="span">
                  State:{' '}
                </Typography>
                <Typography variant="h2" component="span">
                  Pre-inspection
                </Typography>
              </div>
              <PreInspectionPackageDetails pack={preInspectionPack} />
              <FlagCard
                flagIndex={flagIndex}
                flag={currentFlag}
                totalFlags={preInspectionPack.screeningFlags.length}
              />
              <Stack spacing={2} divider>
                <div>{preInspectionPack.screeningFlags.map((flag) => flag.itemTitle)}</div>
              </Stack>
              <Stack>
                <PreInspectionActions onActionClick={handleActionClick} />
                <Stack marginTop={4} className="items-start">
                  <EasyshipButton
                    flat
                    color="primary"
                    onClick={handleGetPrevious}
                    disabled={previousClicked}
                  >
                    <ArrowBackIosNewIcon sx={{ fontSize: '20px' }} />
                    previous package
                  </EasyshipButton>
                </Stack>
              </Stack>
            </Stack>
          )}
        </Container>
      )}
    </Stack>
  )
}
