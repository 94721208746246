import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'

import Loading from '@/components/Loading'
import Package from '@/core/models/Package'
import { usePackage } from '@/pages/PackagePage/PackageProvider'
import useRegularPackageQuery from '@/searchResult/useRegularPackageQuery'

import { CourierReturnForm, CourierReturnFormData } from './CourierReturnForm'
import { CourierReturn } from './models/CourierReturn'
import { useCourierReturnMutation } from './useCourierReturnMutation'

interface CourierReturnSectionProps {
  pack: Package
  courierReturn: CourierReturn
}

function CourierReturnSection({ pack, courierReturn }: CourierReturnSectionProps) {
  const { setBin, updatePackage } = usePackage()
  const navigate = useNavigate()
  const { mutate: mutateCourierReturn, isLoading: isCourierReturnLoading } =
    useCourierReturnMutation()
  const { refetch, isLoading, fetchStatus } = useRegularPackageQuery(pack.id)

  async function updateCourierReturn(formData: CourierReturnFormData): Promise<void> {
    mutateCourierReturn(
      {
        packageId: pack.id,
        courier: {
          ...courierReturn,
          ...formData,
        },
      },
      {
        onSuccess: () => {
          toast.success(`Courier return form saved`)
          if (formData.returnType === 'shipped') {
            navigate('/scan')
          } else {
            if (
              formData.returnReason === 'unknown' ||
              formData.returnReason === 'item_not_received'
            ) {
              return navigate('/scan')
            }
            refetch().then((regularPack) => {
              updatePackage(regularPack.data as Package)
              if (
                regularPack.data?.warehouseState.value === 'xray_completed' &&
                regularPack.data?.courierReturn?.returnReason === 'missorted'
              ) {
                setBin('courier')
              }
            })
          }
        },
      },
    )
  }

  return isLoading && fetchStatus !== 'idle' ? (
    <Loading />
  ) : (
    <CourierReturnForm
      onSubmit={updateCourierReturn}
      isLoading={isCourierReturnLoading}
      initialFormData={courierReturn}
    />
  )
}

export function CourierReturnSectionWrapper() {
  const { pack } = usePackage()

  return pack.warehouseState.value === 'courier_returned' &&
    pack.courierReturn &&
    !pack.courierReturn?.processed_at ? (
    <CourierReturnSection pack={pack} courierReturn={pack.courierReturn} />
  ) : null
}
